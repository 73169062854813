import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  getUserNotification: null,
  networkError: null,
  readStatusCount: null,
  dayWiseUserNotifications: null,
  userEmailNotifications: []

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.GET_USER_NOTIFICATION_LIST: {
    return {
      ...state,
      getUserNotification: action.getUserNotification
    }
  }
  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      isLoading: false,
      networkError: action.networkError
    };
  case actionTypes.GET_READ_STATUS_COUNT_FOR_NOTIFICATION:
    return {
      ...state,
      readStatusCount: action.readStatusCount
    };
  case actionTypes.GET_DAY_WISE_NOTIFICATIONS:
    return {
      ...state,
      dayWiseUserNotifications: action.dayWiseUserNotifications
    };
  case actionTypes.GET_USER_EMAIL_NOTIFICATIONS:
    return {
      ...state,
      userEmailNotifications: action.userEmailNotifications
    };
  default:
    break;
  }
  return state;
};
  
export default reducer;