/* eslint-disable no-console,no-undef,camelcase,no-case-declarations */
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

import * as actionTypes from '../../actions/actionTypes';

const initialState = {
  response: null,
  error: null,
  networkError: null,
  userName: null,
  firstName: null,
  profileImage: null,
  userId: null,
  dailyTip: null,
  motivationalQuote: null,
  dailyActivityData: null,
  wellnessGoals: null,
  achievedGoals: null,
  searchAmigo: null,
  pageNumber: 1,
  amigoList: null,
  requestSent: null,
  sendRequestError: null,
  user: null,
  health: null,
  dailyGoal: null,
  inputGoals: null,
  wellnessJourney: null,
  wellnessVideo: null,
  interest: null,
  wellnessAssessment: {},
  userAuth: null,
  userCompany: null,
  totalAmigoCount: null,
  companyLogo: null,
  userWellnessInitiatives: null,
  biometricPdfFile: null,
  userMonthlyDetails: null,
  monthlyGoal: {},
  listOfThingToComplate: null,
  companyDetails: null,
  completedChallenges: [],
  completedPopupData: [],
  upComingEvent: null,
  slideShowThings: [],
  devicesDisconnected: [],
  monthlyCheckList: [],
  userPermissions: [],
  themesHeader: [],
  UpcomingThemes: [],
  challengeSurveys: [],  
  showBiometricPopUp: null,
  userRecognitionCount: null,
  myUpcomingEvents: null,
  birthdayList: null,
  showHRAPopUp: null,
  workAnniversaryList: null,
  userDetails: null,
  rejectFriendRequest: [],
  userRecommendedChallengeData: [],
  userRecommendedWellnessChallengeData:[],
  liveEvents: null,
  userInitiatives: [],
  createUserInitiativesError: null,
  userInitiativesTrue: null,
  challengeInvitation: [],
  eventsDetailsPoints:null,
  deviceConnects: [],
  setDevices:[],
  userBiometric: [],
  userWbaWellbeing: {},
  userWbaWellbeingV2: {},
  themesHeaderAll:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

  case actionTypes.SET_USER_MONTHLY_DETAILS: {
    return {
      ...state,
      userMonthlyDetails: action.value
    }
  }

  case actionTypes.SET_LIST_OF_THINGS_TO_COMPLETE: {
    return {
      ...state,
      listOfThingToComplate: action.value
    }
  }

  case actionTypes.GET_USER_WELLNESS_INITIATIVE: {
    return {
      ...state,
      userWellnessInitiatives: action.value 
    }
  } 

  case actionTypes.SET_COMPANY_LOGO: {
    return {
      ...state,
      companyLogo: action.logoDetails
    }
  }

  case actionTypes.SET_AMIGO_COUNT:
    return {
      ...state,
      totalAmigoCount: action.count
    };
  
  case actionTypes.SET_INTEREST_DATA:
    return {
      ...state,
      interest: action.interest
    };

  case actionTypes.UPLOAD_PROFILE_PICTURE:
    const duplicate = {...state.response};
    duplicate['user']['profile_image'] = action.profile_image;
    return {
      ...state,
      response: duplicate,
      profileImage: action.profile_image
    };

  case actionTypes.SET_DAILY_GOAL:
    return {
      ...state,
      dailyGoal: action.dailyGoal
    };

  case actionTypes.SET_MONTHLY_GOAL:
    return {
      ...state,
      monthlyGoal: action.monthlyGoal
    };

  case actionTypes.SET_INPUT_ACTIVITY_GOALS:
    return {
      ...state,
      inputGoals: action.inputGoals
    };

  case actionTypes.GET_INPUT_ACTIVITY_GOALS:
    return {
      ...state,
      inputGoals: state.inputGoals
    };

  case actionTypes.SET_WELLNESS_JOURNEY:
    return {
      ...state,
      wellnessJourney: action.wellnessJourney
    };

  case actionTypes.GET_WELLNESS_JOURNEY:
    return {
      ...state,
      wellnessJourney: state.wellnessJourney
    };

  case actionTypes.SET_WELLNESS_VIDEO:
    return {
      ...state,
      wellnessVideo: action.wellnessVideo
    };

  case actionTypes.GET_WELLNESS_VIDEO:
    return {
      ...state,
      wellnessVideo: state.wellnessVideo
    };

  case actionTypes.SET_USER_HEALTH_DATA:
    return {
      ...state,
      health: action.health
    };
  
  case actionTypes.GET_USER_HEALTH_DATA:
    return {
      ...state,
      health: state.health
    };

  case actionTypes.USER_PROFILE_DATA:
    return {
      ...state,
      response: action.response,
    };

  case actionTypes.FETCH_PROFILE_DATA_FAILED:
    return {
      ...state,
      errorMessage: action.error
    };

  case actionTypes.NETWORK_ERROR:
    return {
      ...state,
      networkError: action.networkError,
    };

  case actionTypes.SET_USER_NAME:
    return {
      ...state,
      userName: action.userName
    };

  case actionTypes.SET_USER_FIRST_NAME:
    return {
      ...state,
      firstName: action.userName
    };

  case actionTypes.GET_USER_NAME:
    return {
      ...state,
      userName: state.userName,
    };

  case actionTypes.SET_USER_PROFILE_IMAGE:
    return {
      ...state,
      profileImage: action.profileImage
    };

  case actionTypes.GET_USER_PROFILE_IMAGE:
    return {
      ...state,
      profileImage: state.profileImage,
    };

  case actionTypes.SET_USER_ID:
    return {
      ...state,
      userId: action.id
    };

  case actionTypes.GET_USER_ID:
    return {
      ...state,
      userId: state.userId
    };

  case actionTypes.SET_DAILY_TIP:
    return {
      ...state,
      dailyTip: action.dailyTip
    };

  case actionTypes.GET_DAILY_TIP:
    return {
      ...state,
      dailyTip: state.dailyTip
    };

  case actionTypes.UPDATE_DAILY_TIP:
    const obj = {
      daily_tip: state.dailyTip.daily_tip,
      show_tip: action.show_tip
    }
    return {
      ...state,
      dailyTip: obj
    }

  case actionTypes.SET_BIOMETRIC_FORM_DATA:
    return {
      ...state,
      biometricPdfFile: action.biometric_form_pdf
    }  

  case actionTypes.SET_MOTIVATIONAL_QUOTE:
    return {
      ...state,
      motivationalQuote: action.motivationalQuote
    };

  case actionTypes.GET_MOTIVATIONAL_QUOTE:
    return {
      ...state,
      motivationalQuote: state.motivationalQuote
    };

  case actionTypes.SET_DAILY_ACTIVITY_DATA:
    return {
      ...state,
      dailyActivityData: action.dailyActivityData
    };

  case actionTypes.GET_DAILY_ACTIVITY_DATA:
    return {
      ...state,
      dailyActivityData: state.dailyActivityData
    };

  case actionTypes.SET_WELLNESS_GOALS:
    return {
      ...state,
      wellnessGoals: action.wellnessGoals
    };

  case actionTypes.GET_WELLNESS_GOALS:
    return {
      ...state,
      wellnessGoals: state.wellnessGoals
    };
  
  case actionTypes.SET_BIOMETRIC_POPUP:
    return {
      ...state,
      showBiometricPopUp: action.showBiometricPopUp
    }
  case actionTypes.UPDATE_WELLNESS_GOALS:
    return {
      ...state,
      wellnessGoals: action.goals
    }

  case actionTypes.SET_ACHIEVED_GOALS:
    return {
      ...state,
      achievedGoals: action.achievedGoals
    };

  case actionTypes.GET_ACHIEVED_GOALS:
    return {
      ...state,
      achievedGoals: state.achievedGoals
    };

  case actionTypes.SAVE_SEARCH_AMIGO_INFO:
    let searchAmigo = {};
    if(isNull(state.searchAmigo)) {
      searchAmigo[action.name] = action.value
    } else {
      searchAmigo = {...state.searchAmigo};
      searchAmigo[action.name] = action.value;
    }

    return {
      ...state,
      searchAmigo
    };
  case actionTypes.CLEAR_SEARCH_AMIGO_INFO:
    searchAmigo = {};
    return {
      ...state,
      searchAmigo
    };
  case actionTypes.CLEAR_SEARCH_AMIGO_LIST:
    amigoList = null;
    return {
      ...state,
      amigoList,
      isLoading: false
    };

  case actionTypes.SEARCH_AMIGO:
    let amigoList = [];
    amigoList = action.pageNumber === 1 || (action.pageNumber === state.pageNumber - 1) || isUndefined(state.amigoList) || isNull(state.amigoList) ? [...action.amigoList] : [
      ...state.amigoList,
      ...action.amigoList
    ];
    return {
      ...state,
      amigoList,
      isLoading: false
    };

  case actionTypes.FAILED_SEARCH_AMIGO:
    return {
      ...state,
      searchAmigoError: action.searchAmigoError,
      isLoading: false
    };

  case actionTypes.GET_PAGE_NUMBER:
    return {
      ...state,
      pageNumber: state.pageNumber
    };

  case actionTypes.SET_PAGE_NUMBER:
    return {
      ...state,
      pageNumber: action.pageNumber
    };

  case actionTypes.SEND_FRIEND_REQUEST:
    return {
      ...state,
      requestSent: action.requestSent
    };

  case actionTypes.FAILED_SEND_FRIEND_REQUEST:
    return {
      ...state,
      sendRequestError: action.sendRequestError
    };

  case actionTypes.UPDATE_AMIGO:
    let amigo = state.amigoList.filter((amigo) => amigo.uid === action.id);
    let index = state.amigoList.findIndex((amigo) => amigo.uid === action.id);
    let updatedData = [...state.amigoList];
    if(index > -1) {
      amigo[0].status = 'Pending';
      updatedData[index] = amigo[0];
    }

    return {
      ...state,
      amigoList: updatedData
    };

  case actionTypes.SET_USER_DATA: 
    return {
      ...state,
      user: action.user
    };

  case actionTypes.GET_USER_DATA: 
    return {
      ...state,
      user: state.user
    };

  case actionTypes.SET_WELLNESS_ASSESSMENT:
    return {
      ...state,
      wellnessAssessment: action.wellnessAssessment
    };

  case actionTypes.GET_WELLNESS_ASSESSMENT:
    return {
      ...state,
      wellnessAssessment: isNull(state.wellnessAssessment) ? {} : state.wellnessAssessment
    };

  case actionTypes.SET_USER_AUTH:
    return {
      ...state,
      userAuth: action.userAuth
    };

  case actionTypes.USER_PROFILE_LOADING:
    return {
      ...state,
      isLoading: true
    };

  case actionTypes.SAVE_WELLBEING_ASSESSMENT_SUCCESS:
    return {
      ...state,
      success: action.success,
      isLoading: false,
      error: null
    };

  case actionTypes.SAVE_WELLBEING_ASSESSMENT_ERROR:
    return {
      ...state,
      error: action.error,
      isLoading: false
    };

  case actionTypes.UPDATE_WELLNESS_LOCK_STATUS:
    const newResponse = {...state.response};
    if(newResponse.wellness_journey.id === action.id) {
      newResponse.wellness_journey.lock_status = 1
    }
    return {
      ...state,
      response: newResponse
    };

  case actionTypes.SET_COMPANY_FOR_USER:
    return {
      ...state,
      userCompany: {
        id: action.id,
        name: action.name,
        companyStartDate: action.companyStartDate,
        showBiometricStatus: action.showBiometricStatus,
        showTeam: action.showTeam
      }
    };

  case actionTypes.GET_COMPANY_FOR_USER:
    return {
      ...state,
      userCompany: state.userCompany
    };
  case actionTypes.CHALLENGE_EVENT_FOR_SPOUSE_DEPENDENT:
    return {
      ...state,
      companyDetails: action.companyDetails
    };
  case actionTypes.GET_COMPANY_WITH_SPOUSE_DEPENDENT:
    return {
      ...state,
      companyDetails: state.companyDetails
    };
  case actionTypes.GET_COMPLETED_CHALLENGES:
    return {
      ...state,
      completedChallenges: state.completedChallenges
    };

  case actionTypes.USER_CHALLENGE_COMPLETED_POPUP:
    return {
      ...state,
      completedPopupData: action.completedPopupData
    };
  case actionTypes.SET_COMPLETED_CHALLENGES:
    return {
      ...state,
      completedChallenges: action.completedChallenges
    };

  case actionTypes.USER_RECOMMENDED_CHALLENGE:
    return {
      ...state,
      userRecommendedChallengeData: action.userRecommendedChallengeData
    };
  case actionTypes.USER_RECOMMENDED_WELLNESS_CHALLANGE:
    return {
      ...state,
      userRecommendedWellnessChallengeData: action.userRecommendedWellnessChallengeData
    }
  case actionTypes.UPCOMING_EVENT:
    return {
      ...state,
      upComingEvent: action.upComingEvent
    };
  case actionTypes.GET_RECOMMENDED_THINGS_TO_COMPLETE:
    return {
      ...state,
      slideShowThings: action.slideShowThings
    };
  case actionTypes.SET_DEVICE_DISCONNECTION:
    return {
      ...state,
      devicesDisconnected: action.devicesDisconnected
    };
  case actionTypes.GET_MONTHLY_TASK_LIST:
    return {
      ...state,
      monthlyCheckList: action.monthlyCheckList
    };
  case actionTypes.GET_USER_PERMISSIONS_LIST:
    return {
      ...state,
      userPermissions: action.userPermissions
    };
  case actionTypes.SET_THEMES_HEADER:
    return {
      ...state,
      themesHeader: action.themesHeader
    };
  case actionTypes.SET_ALL_THEMES_HEADER:
    return {
      ...state,
      themesHeaderAll: action.themesHeaderAll
    };  
  case actionTypes.GET_UPCOMING_THEMES:
    return {
      ...state,
      UpcomingThemes: action.UpcomingThemes
    };
  case actionTypes.SET_CHALLENGE_SURVEYS:
    return {
      ...state,
      challengeSurveys: action.challengeSurveys
    };
  case actionTypes.GET_USER_RECOGNITION_COUNT:
    return {
      ...state,
      userRecognitionCount: action.userRecognitionCount
    };
  case actionTypes.GET_MY_UPCOMING_EVENTS:
    return {
      ...state,
      myUpcomingEvents: action.myUpcomingEvents
    };
  case actionTypes.GET_BIRTHDAYS_LIST:
    return {
      ...state,
      birthdayList: action.birthdayList
    };
  case actionTypes.SET_HRA_POPUP:
    return {
      ...state,
      showHRAPopUp: action.showHRAPopUp
    };
  case actionTypes.GET_WORK_ANNIVERSARY_LIST:
    return {
      ...state,
      workAnniversaryList: action.workAnniversaryList
    };
  case actionTypes.UPDATE_USER_DETAILS:
    return {
      ...state,
      userDetails: action.userDetails
    };
  case actionTypes.REJECT_FRIEND_REQUEST:
    return {
      ...state,
      rejectFriendRequest: action.rejectFriendRequest
    };
  case actionTypes.GET_LIVE_EVENTS:
    return {
      ...state,
      liveEvents: action.liveEvents
    };  
  case actionTypes.GET_USER_INITIATIVES: {
    return {
      ...state,
      userInitiatives: action.userInitiatives
    }
  }
  case actionTypes.SUCCESSFULLY_USER_INITIATIVES:
    return {
      ...state,
      userInitiativesTrue: action.userInitiativesTrue,
      isLoading: false,
      createUserInitiativesError: null
    };

  case actionTypes.FAILED_USER_INITIATIVES:
    return {
      ...state,
      createUserInitiativesError: action.createUserInitiativesError,
      isLoading: false
    };
  case actionTypes.USER_CHALLENGE_INVITATION:
    return {
      ...state,
      challengeInvitation: action.challengeInvitation,
    };
  case actionTypes.GET_EVENTS_DETAILS_POINTS:
    return {
      ...state,
      eventsDetailsPoints: action.eventsDetailsPoints,
    }; 
  case actionTypes.GET_CONNECTED_DEVICES:
    return {
      ...state,
      deviceConnects: action.deviceConnects
    }; 
  case actionTypes.SET_CONNECTED_DEVICES:
    return {
      ...state,
      setDevices: action.setDevices
    };  
  case actionTypes.GET_BIOMETRIC_DATA:
    return {
      ...state,
      userBiometric: action.userBiometric
    };  
  case actionTypes.GETUSER_WELLBEING_ASSESSMENT_DETAILS:
    return {
      ...state,
      userWbaWellbeing: action.userWbaWellbeing
    };
  case actionTypes.GETUSER_WELLBEING_ASSESSMENT_DETAILSV2:
    return {
      ...state,
      userWbaWellbeingV2: action.userWbaWellbeingV2
    };  

  default: break;
  }
  return state;
};

export default reducer;
